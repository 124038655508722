import NotificationWithAction from "@components/NotificationWithAction/NotificationWithAction";
import { Button } from "@mui/material";
import { AppRoutes } from "@utils/enums";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NotFound() {
  const { t } = useTranslation();
  return (
    <NotificationWithAction
      helmetTitle={t("not_found.helmet")}
      title={t("not_found.title")}
      message={t("not_found.message")}
      buttonComponent={
        <Button component={Link} to={AppRoutes.Dashboard} variant="contained" size="large" sx={{ mt: 3, width: "50%" }}>
          {t("not_found.button")}
        </Button>
      }
    />
  );
}

export default NotFound;
