import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { TimelineItem } from "@components/TimelineItem/TimelineItem";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import EditPatientModal from "@components/modals/Patients/EditPatientModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Stack, Typography, alpha, styled } from "@mui/material";
import { useEditPatientMutation, useUnassignEmployeeFromPatientMutation } from "@store/services/patientsService";
import { handleCloseModal } from "@utils/modalUtils";
import { formatDate, calculateAge } from "@utils/timeUtils"; // dodano calculateAge
import { capitalizeWord, formatSexString } from "@utils/typographyUtils";
import { IEmployee } from "employees-types";
import { IPatient } from "patients-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export const StyledEmployeeBox = styled(Box)(({ theme }) => ({
  borderRadius: Number(theme.shape.borderRadius) * 5,
  backgroundColor: alpha(theme.palette.grey[400], 0.12),
}));
interface IPatientDataWidgetProps extends SnackbarProps {
  patient: IPatient;
  isActive: boolean;
}

const PatientDataWidget: React.FC<IPatientDataWidgetProps> = ({ patient, isActive, snackbarShowMessage }) => {
  const { t } = useTranslation();

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [isEditPatientModalOpen, toggleEditPatientModal] = useModal();
  const [isUnassignEmployeeToPatientModalOpen, toggleUnassignEmployeeToPatientModal] = useModal();

  const handleCloseUnassignEmployeeToPatientModal = () => handleCloseModal([], toggleUnassignEmployeeToPatientModal);

  const [editPatient, { isLoading: isEditPatientLoading }] = useEditPatientMutation();
  const [unassignEmployeeFromPatient, { isLoading: isUnassignEmployeeLoading }] = useUnassignEmployeeFromPatientMutation();

  const handleOpenUnassignEmployeeToPatientModal = useCallback(
    (employee: IEmployee) => {
      setSelectedEmployee(employee);
      toggleUnassignEmployeeToPatientModal();
    },
    [setSelectedEmployee, toggleUnassignEmployeeToPatientModal]
  );

  const handleEditPatient = useCallback(
    (patientData: Partial<IPatient>) => {
      if (!patient) return;

      editPatient({ patientId: patient._id, data: patientData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("patient_details.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          toggleEditPatientModal();
        });
    },
    [editPatient, snackbarShowMessage, toggleEditPatientModal, patient?._id]
  );

  const handleUnassignEmployeeToPatient = useCallback(() => {
    if (!selectedEmployee || !patient) return;

    unassignEmployeeFromPatient({ patientId: patient._id, employeeId: selectedEmployee._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("patient_details.snackbar_message.unassign_employee_from_patient.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("patient_details.snackbar_message.unassign_employee_from_patient.error"), "error");
      })
      .finally(() => {
        handleCloseUnassignEmployeeToPatientModal();
      });
  }, [snackbarShowMessage, selectedEmployee]);

  return (
    <>
      <Card>
        <CardHeader
          title={t("patient_details.data.title")}
          action={
            <RBAC
              action={RBACActions.ACTION_EDIT_PATIENT}
              yes={() => (
                <IconWithTooltip
                  label={t("patient_details.button.edit_data")}
                  icon={<EditNoteRoundedIcon />}
                  onClick={toggleEditPatientModal}
                  locked={{ isLocked: !isActive }}
                />
              )}
            />
          }
        />
        <Divider sx={{ borderStyle: "dashed", mt: 2 }} />
        <CardContent>
          <Stack direction="column" spacing={2}>
            <TimelineItem color="info" title={capitalizeWord(t(formatSexString(patient.sex)))} subtitle={t("personal_data.gender")} />
            <TimelineItem
              color="primary"
              title={patient.dateOfBirth ? calculateAge(new Date(patient.dateOfBirth)) : null}
              subtitle={t("personal_data.age")}
            />
            <TimelineItem
              color="error"
              title={patient.dateOfBirth ? formatDate(new Date(patient.dateOfBirth), "dd.MM.yyyy") : t("personal_data.no_data")}
              subtitle={t("personal_data.date_of_birth")}
            />
            <TimelineItem
              color="success"
              title={patient.email ? patient.email : t("personal_data.no_email")}
              subtitle={t("personal_data.email")}
              id="patient-email"
            />
            <TimelineItem
              color="secondary"
              title={patient.phoneNumber ? patient.phoneNumber : t("patient.no_phone_number_title")}
              subtitle={t("personal_data.phone_number")}
              id="patient-phone-number"
            />
          </Stack>
          {!!patient.assignedEmployees.length && (
            <>
              <Divider sx={{ borderStyle: "dashed", my: 2 }} />
              <Typography variant="subtitle1" gutterBottom>
                {t("patient_details.data.assigned_employee")}
              </Typography>
              <Stack gap={1}>
                {patient.assignedEmployees.map((employee) => (
                  <StyledEmployeeBox sx={{ p: 2 }} key={employee._id}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack gap={1} direction="row" alignItems="center">
                        <Avatar src={employee?.avatar} />
                        <Stack>
                          <Typography variant="subtitle2" sx={{ color: "text.primary", mb: -0.3 }} noWrap>
                            {`${employee.firstname} ${employee.lastname}`}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                            {employee?.email}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                            {employee?.phoneNumber}
                          </Typography>
                        </Stack>
                      </Stack>
                      <RBAC
                        action={RBACActions.ACTION_UNASSIGN_PATIENT_FROM_EMPLOYEE}
                        yes={() => (
                          <IconWithTooltip
                            label={t("patient_details.data.assigned_employee_remove_button")}
                            icon={<ClearRoundedIcon />}
                            onClick={() => handleOpenUnassignEmployeeToPatientModal(employee)}
                          />
                        )}
                      />
                    </Stack>
                  </StyledEmployeeBox>
                ))}
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
      <EditPatientModal
        open={isEditPatientModalOpen}
        onClose={toggleEditPatientModal}
        onSubmit={handleEditPatient}
        initialValues={patient}
        loading={isEditPatientLoading}
      />
      <ConfirmationModal
        open={isUnassignEmployeeToPatientModalOpen}
        onClose={handleCloseUnassignEmployeeToPatientModal}
        onConfirm={handleUnassignEmployeeToPatient}
        title={t("patient_details.assign_employee_to_patient_modal.title")}
        content={t("patient_details.assign_employee_to_patient_modal.content_text")}
        loading={isUnassignEmployeeLoading}
      />
    </>
  );
};

export default withSnackbar(PatientDataWidget);
