import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Stack, Checkbox, ListItemText, SelectChangeEvent } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { SortBy, SortOrder } from "@utils/enums";
import { useTranslation } from "react-i18next";
import SortOrderButton from "@components/SortOrderButton/SortOrderButton";
import { IEmployee } from "employees-types";
import RBAC from "@auth/RBAC/RBAC";
import { RBACActions } from "@auth/rbac-rules";

interface IFilterControlsProps {
  employees?: IEmployee[];
  selectedEmployees?: string[];
  searchText: string;
  sortBy: SortBy;
  sortOrder: SortOrder;
  setSearchText: (value: string) => void;
  setSortBy: (value: SortBy) => void;
  setSortOrder: (value: SortOrder) => void;
  handleEmployeeChange?: (event: SelectChangeEvent<string[]>) => void;
}

const FilterControls: React.FC<IFilterControlsProps> = ({
  selectedEmployees,
  employees,
  searchText,
  sortBy,
  sortOrder,
  setSearchText,
  setSortBy,
  setSortOrder,
  handleEmployeeChange,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ p: 3 }} spacing={3}>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          label={t("filter.by_firstname_lastname")}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: <SearchRoundedIcon color="action" />,
          }}
        />
      </Grid>
      {selectedEmployees && (
        <RBAC
          action={RBACActions.ACTION_FILTER_PATIENTS_BY_EMPLOYEE}
          yes={() => (
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>{t("filter.by_employee")}</InputLabel>
                <Select
                  multiple
                  value={selectedEmployees}
                  onChange={handleEmployeeChange}
                  renderValue={(selected) => {
                    const selectedEmployeesNames = selected.map((employeeId) => {
                      const employee = employees.find((e) => e._id === employeeId);
                      return employee ? `${employee.firstname} ${employee.lastname}` : "";
                    });
                    return selectedEmployeesNames.join(", ");
                  }}
                >
                  {employees.map((employee) => (
                    <MenuItem key={employee._id} value={employee._id}>
                      <Checkbox checked={selectedEmployees.indexOf(employee._id) > -1} />
                      <ListItemText primary={`${employee.firstname} ${employee.lastname}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        />
      )}
      <Grid item xs={12} sm={3}>
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControl fullWidth>
            <InputLabel>{t("sort.sort_by")}</InputLabel>
            <Select value={sortBy} onChange={(e) => setSortBy(e.target.value as SortBy)}>
              <MenuItem value={SortBy.FIRST_NAME}>{t("sort.by_firstname")}</MenuItem>
              <MenuItem value={SortBy.LAST_NAME}>{t("sort.by_lastname")}</MenuItem>
            </Select>
          </FormControl>
          <SortOrderButton
            sortOrder={sortOrder}
            onToggleSortOrder={() => setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FilterControls;
