import ActivityLog from "@pages/ActivityLog/ActivityLog";
import Appointments from "@pages/Appointments/Appointments";
import CreateInvoice from "@pages/CreateInvoice/CreateInvoice";
import EmployeeDetails from "@pages/EmployeeDetails/EmployeeDetails";
import Employees from "@pages/Employees/Employees";
import Invoices from "@pages/Invoices/Invoices";
import Main from "@pages/Main/Main";
import MyClinic from "@pages/MyClinic/MyClinic";
import NotFound from "@pages/NotFound/NotFound";
import PasswordReset from "@pages/PasswordReset/PasswordReset";
import PatientDetails from "@pages/PatientDetails/PatientDetails";
import PatientDiagnosis from "@pages/PatientDiagnosis/PatientDiagnosis";
import PatientHomeworks from "@pages/PatientHomeworks/PatientHomeworks";
import PatientReports from "@pages/PatientReports/PatientReports";
import Patients from "@pages/Patients/Patients";
import PreviewInvoice from "@pages/PreviewInvoice/PreviewInvoice";
import Reports from "@pages/Reports/Reports";
import Settings from "@pages/Settings/Settings";
import SignIn from "@pages/SignIn/SignIn";
import VerifyAssignment from "@pages/VerifyAssignment/VerifyAssignment";
import VerifyAssignmentFailed from "@pages/VerifyAssignment/VerifyAssignmentFailed";
import { Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "src/hoc/AuhenticatedRoute";
import Layout from "./../layout/Layout";
import { AppRoutes } from "./../utils/enums";
import PasswordResetSuccess from "@pages/PasswordReset/PasswordResetSuccess";
import ChangePassword from "@pages/ChangePassword/ChangePassword";
import ChangePasswordSuccess from "@pages/ChangePassword/ChangePasswordSuccess";
import ConfirmRegistration from "@pages/ConfirmRegistration/ConfirmRegistration";
import ConfirmRegistrationSuccess from "@pages/ConfirmRegistration/ConfirmRegistrationSuccess";
import SignUp from "@pages/SignUp/SignUp";
import ConfirmRegistrationPatient from "@pages/ConfirmRegistrationPatient/ConfirmRegistrationPatient";
import ConfirmRegistrationPatientSuccess from "@pages/ConfirmRegistrationPatient/ConfirmRegistrationPatientSuccess";
import SignUpSuccess from "@pages/SignUp/SignUpSuccess";
import Expenses from "@pages/Expenses/Expenses";

const AppRouter = (props: any): JSX.Element => {
  return (
    <Routes>
      <Route
        path={AppRoutes.Dashboard}
        element={
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        }
      >
        <Route index element={<Main />} />
        <Route path={AppRoutes.MyClinic} element={<MyClinic {...props} />} />
        <Route path={AppRoutes.Reports} element={<Reports {...props} />} />
        <Route path={AppRoutes.PatientReports} element={<PatientReports {...props} />} />
        <Route path={AppRoutes.Employees} element={<Employees {...props} />} />
        <Route path={AppRoutes.EmployeeDetails} element={<EmployeeDetails {...props} />} />
        <Route path={AppRoutes.Expenses} element={<Expenses {...props} />} />
        <Route path={AppRoutes.Patients} element={<Patients {...props} />} />
        <Route path={AppRoutes.PatientDetails} element={<PatientDetails {...props} />} />
        <Route path={AppRoutes.PatientDiagnosis} element={<PatientDiagnosis {...props} />} />
        <Route path={AppRoutes.PatientHomeworks} element={<PatientHomeworks {...props} />} />
        <Route path={AppRoutes.Appointments} element={<Appointments {...props} />} />
        <Route path={AppRoutes.Invoices} element={<Invoices {...props} />} />
        <Route path={AppRoutes.CreateInvoice} element={<CreateInvoice {...props} />} />
        <Route path={AppRoutes.PreviewInvoice} element={<PreviewInvoice {...props} />} />
        <Route path={AppRoutes.ActivityLog} element={<ActivityLog {...props} />} />
        <Route path={AppRoutes.Settings} element={<Settings {...props} />} />
      </Route>
      <Route path={AppRoutes.Login} element={<SignIn {...props} />} />
      <Route path={AppRoutes.Register} element={<SignUp {...props} />} />
      <Route path={AppRoutes.VerifyAssignment} element={<VerifyAssignment {...props} />} />
      <Route path={AppRoutes.VerifyAssignmentFailed} element={<VerifyAssignmentFailed {...props} />} />
      <Route path={AppRoutes.PasswordReset} element={<PasswordReset {...props} />} />
      <Route path={AppRoutes.PasswordResetSuccess} element={<PasswordResetSuccess {...props} />} />
      <Route path={AppRoutes.ChangePassword} element={<ChangePassword {...props} />} />
      <Route path={AppRoutes.ChangePasswordSuccess} element={<ChangePasswordSuccess {...props} />} />
      <Route path={AppRoutes.ConfirmRegistration} element={<ConfirmRegistration {...props} />} />
      <Route path={AppRoutes.ConfirmRegistrationSuccess} element={<ConfirmRegistrationSuccess {...props} />} />
      <Route path={AppRoutes.ConfirmRegistrationPatient} element={<ConfirmRegistrationPatient {...props} />} />
      <Route path={AppRoutes.ConfirmRegistrationPatientSuccess} element={<ConfirmRegistrationPatientSuccess {...props} />} />
      <Route path={AppRoutes.SignUpSuccess} element={<SignUpSuccess {...props} />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
