import Logo from "@components/Logo/Logo";
import { AlertColor, Link, Typography } from "@mui/material";
import { selectCurrentUserTokens } from "@store/features/authSlice";
import { useRegisterClientMutation } from "@store/services/authService";
import { StyledCard } from "@theme/styled-components/AuthCard";
import { Background } from "@theme/styled-components/Background";
import { AppRoutes } from "@utils/enums";
import { ILoginResponse } from "auth-types";
import { useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { withSnackbar } from "src/hoc/AlertPopover";
import { authValidationSchema } from "src/validations/authValidation";
import SignUpForm from "./fragments/SignUpForm";

// TODO: Add translations here
const SignUp: React.FC = ({ snackbarShowMessage }: { snackbarShowMessage: (message: string, severity?: AlertColor) => void }) => {
  const { t } = useTranslation();

  const [register, { isLoading }] = useRegisterClientMutation();
  const usersTokens: ILoginResponse = useSelector(selectCurrentUserTokens);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: authValidationSchema,
    onSubmit: (values) => {
      register({
        email: values.email,
        password: values.password,
      })
        .unwrap()
        .then(() => {
          navigate(AppRoutes.SignUpSuccess, { replace: true });
        })
        .catch((error) => snackbarShowMessage(error.data.message, "error"));
    },
  });

  if (usersTokens) return <Navigate replace to={AppRoutes.Dashboard} />;

  return (
    <>
      <Helmet>
        <title>{t("signup.helmet")}</title>
      </Helmet>
      <Background />
      <Logo />
      <StyledCard>
        <Typography variant="h6" sx={{ mb: -0.5 }}>
          {t("signup.title")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }} color="text.secondary">
          {t("Masz już konto?")}{" "}
          <Link
            href={AppRoutes.Login}
            variant="subtitle2"
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("signup.login")}
          </Link>
        </Typography>
        <SignUpForm {...{ formik }} {...{ isLoading }} />
      </StyledCard>
    </>
  );
};

export default withSnackbar(SignUp);
