import { styled } from "@mui/system";
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import AppSidebar from "../components/AppSidebar/AppSidebar";
import Header from "../components/Header/Header";
import { IUserContextType, UserContext } from "@context/UserProvider";
import { ClinicContext, IClinicContext } from "@context/ClinicProvider";
import { Fab } from "@mui/material";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    bottom: theme.spacing(6),
  },
}));

const Layout: React.FC = () => {
  const { userDetails } = useContext(UserContext) as IUserContextType;
  const { currentClinic } = useContext(ClinicContext) as IClinicContext;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledRoot>
      <Header {...{ userDetails }} onOpenNav={() => setOpen(true)} />
      <AppSidebar {...{ userDetails, currentClinic }} openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
      {/* @ts-expect-error */}
      <StyledFab color="primary" onClick={() => window.USETIFUL.checklist.start("01J5BMXSE1EZFSCPN8FJTA7QFM")}>
        <QuestionMarkRoundedIcon sx={{ fontSize: 36 }} />
      </StyledFab>
    </StyledRoot>
  );
};

export default Layout;
