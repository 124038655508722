import { Theme } from "@mui/material";

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: `2px solid ${theme.palette.grey[200]}`,
        },
      },
    },
  };
}
