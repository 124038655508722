import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import ActionMenu from "@components/ActionMenu/ActionMenu";
import PageTitle from "@components/PageTitle/PageTitle";
import FileUploadModal from "@components/modals/FileUploadModal";
import { ClinicContext } from "@context/ClinicProvider";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import useUploadFile from "@hooks/useUploadFile";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HideImageRoundedIcon from "@mui/icons-material/HideImageRounded";
import { Container, Divider, Grid, ListItemIcon, MenuItem, Stack, Tab, Tabs, Typography } from "@mui/material";
import { CustomTabPanel } from "@pages/Settings/Settings";
import { removeClinicId } from "@store/features/clinicSlice";
import { useDeleteClinicLogoMutation, useDeleteClinicMutation, useEditClinicMutation } from "@store/services/clinicsService";
import { AppRoutes } from "@utils/enums";
import { IClinic } from "clinics-types";
import { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppointmentTypesSection from "./fragments/AppointmentTypesSection";
import AppointmentsStatusSection from "./fragments/AppointmentsStatusSection";
import ClinicBasicInfo from "./fragments/ClinicBasicInfo";
import ClinicMenu from "./fragments/ClinicMenu";
import InvoiceDataSection from "./fragments/InvoiceDataSection";
import DeleteClinicModal from "./modals/DeleteClinicModal";
import EditClinicModal from "./modals/EditClinicModal";
import ClinicNotifications from "./fragments/ClinicNotifications";

function MyClinic({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);
  const [isEditClinicModalOpen, toggleEditClinicModal] = useModal();
  const [isDeleteClinicModalOpen, toggleDeleteClinicModal] = useModal();
  const [isFileUploadModalOpen, toggleFileUploadModalOpen] = useModal();

  const userRole = useContext(RoleContext);
  const { currentClinic } = useContext(ClinicContext);

  const [editClinic, { isLoading: isEditClinicLoading }] = useEditClinicMutation();
  const [deleteClinic, { isLoading: isDeleteClinicLoading }] = useDeleteClinicMutation();
  const [deleteClinicLogo] = useDeleteClinicLogoMutation();
  const [uploadClinicLogo, { isLoading }] = useUploadFile("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElementMenu(event.currentTarget);
    },
    [setAnchorElementMenu]
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleEditClinic = useCallback(
    (clinicData: Partial<IClinic>) => {
      if (!currentClinic._id) return;

      editClinic({ clinicId: currentClinic._id, data: clinicData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("my_clinic.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("my_clinic.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          toggleEditClinicModal();
        });
    },
    [editClinic, snackbarShowMessage, toggleEditClinicModal, currentClinic]
  );

  const handleDeleteClinic = useCallback(() => {
    if (!currentClinic._id) return;

    deleteClinic({ clinicId: currentClinic._id })
      .unwrap()
      .then(() => {
        dispatch(removeClinicId());
        snackbarShowMessage(t("my_clinic.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("my_clinic.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        toggleDeleteClinicModal();
        navigate(AppRoutes.Dashboard);
      });
  }, [deleteClinic, dispatch, snackbarShowMessage, toggleDeleteClinicModal, currentClinic]);

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_CLINICS)) navigate(AppRoutes.Dashboard);

  const handleFileUploadLogo = useCallback(
    (values: { files: File[] }) => {
      if (!Array.isArray(values.files) || values.files.length === 0) return;
      uploadClinicLogo(values.files)
        .then(() => {
          snackbarShowMessage(t("file.upload.success_message"), "success");
          window.location.reload();
        })
        .catch(() => {
          snackbarShowMessage(t("file.upload.error_message"), "error");
        })
        .finally(() => {
          toggleFileUploadModalOpen();
        });
    },
    [snackbarShowMessage, toggleFileUploadModalOpen]
  );

  const handleDeleteClinicLogo = useCallback(() => {
    if (!currentClinic.files[0]._id) return;
    deleteClinicLogo({ clinicId: currentClinic?._id, fileId: currentClinic.files[0]._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("file.delete.success_message"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("file.delete.error_message"), "error");
      });
  }, [snackbarShowMessage, currentClinic]);

  return (
    <>
      <Helmet>
        <title>{t("my_clinic.helmet")}</title>
      </Helmet>
      <Container>
        <PageTitle
          titleId="my-clinic-page-title"
          title={currentClinic?.name}
          avatarSrc={currentClinic?.files[0]?.url || ""}
          rightContent={
            <Stack direction="row" spacing={2}>
              <ActionMenu
                menuItems={
                  <>
                    <MenuItem onClick={toggleEditClinicModal}>
                      <ListItemIcon>
                        <EditRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("my_clinic.menu.edit")}
                      </Typography>
                    </MenuItem>
                    {!currentClinic?.files[0] && (
                      <>
                        <Divider />
                        <MenuItem onClick={toggleFileUploadModalOpen}>
                          <ListItemIcon>
                            <CloudUploadRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap>
                            {t("my_clinic.menu.add_logo")}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                    <Divider />
                    {currentClinic?.files[0] && (
                      <MenuItem onClick={handleDeleteClinicLogo}>
                        <ListItemIcon>
                          <HideImageRoundedIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap color="error">
                          {t("my_clinic.menu.delete_logo")}
                        </Typography>
                      </MenuItem>
                    )}
                    <MenuItem onClick={toggleDeleteClinicModal}>
                      <ListItemIcon>
                        <DeleteRoundedIcon fontSize="small" color="error" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap color="error">
                        {t("my_clinic.button.delete")}
                      </Typography>
                    </MenuItem>
                  </>
                }
                buttonLabel={t("my_clinic.menu.title")}
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
              />
            </Stack>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs value={value} onChange={handleChange} variant="scrollable">
              <Tab label={t("my_clinic.settings.clinic_data")} value={0} />
              {rbacCheck(userRole, RBACActions.ACTION_GET_APPOINTMENT_TYPES) && (
                <Tab label={t("my_clinic.settings.appointments_types")} value={1} id="my-clinic-settings-appointments-types" />
              )}
              {rbacCheck(userRole, RBACActions.ACTION_GET_APPOINTMENTS_STATUS) && (
                <Tab label={t("my_clinic.settings.appointments_status")} value={2} id="my-clinic-settings-appointments-status" />
              )}
              {rbacCheck(userRole, RBACActions.ACTION_GET_INVOICE_DATA) && (
                <Tab label={t("my_clinic.settings.invoice_data")} value={3} id="my-clinic-settings-invoice-data" />
              )}
              {rbacCheck(userRole, RBACActions.ACTION_GET_INVOICE_DATA) && (
                <Tab label={t("my_clinic.settings.notifications_settings")} value={4} id="notification-tab" />
              )}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <ClinicBasicInfo currentClinic={currentClinic} handleOpenMenu={handleOpenMenu} />
            </CustomTabPanel>
            {rbacCheck(userRole, RBACActions.ACTION_GET_APPOINTMENT_TYPES) && (
              <CustomTabPanel value={value} index={1}>
                <AppointmentTypesSection {...{ snackbarShowMessage }} />
              </CustomTabPanel>
            )}
            {rbacCheck(userRole, RBACActions.ACTION_GET_APPOINTMENTS_STATUS) && (
              <CustomTabPanel value={value} index={2}>
                <AppointmentsStatusSection {...{ snackbarShowMessage }} />
              </CustomTabPanel>
            )}
            {rbacCheck(userRole, RBACActions.ACTION_GET_INVOICE_DATA) && (
              <CustomTabPanel value={value} index={3}>
                <InvoiceDataSection {...{ snackbarShowMessage }} />
              </CustomTabPanel>
            )}
            {rbacCheck(userRole, RBACActions.ACTION_GET_INVOICE_DATA) && (
              <CustomTabPanel value={value} index={4}>
                <ClinicNotifications {...{ snackbarShowMessage }} />
              </CustomTabPanel>
            )}
          </Grid>
        </Grid>
      </Container>
      <DeleteClinicModal
        open={isDeleteClinicModalOpen}
        loading={isDeleteClinicLoading}
        clinicName={currentClinic && currentClinic.name}
        onClose={toggleDeleteClinicModal}
        onSubmit={handleDeleteClinic}
      />
      <EditClinicModal
        open={isEditClinicModalOpen}
        onClose={toggleEditClinicModal}
        onSubmit={handleEditClinic}
        initialValues={currentClinic}
        loading={isEditClinicLoading}
      />
      <ClinicMenu {...{ anchorElementMenu }} {...{ handleCloseMenu }} handleEditModalOpen={toggleEditClinicModal} />
      <FileUploadModal open={isFileUploadModalOpen} loading={isLoading} onClose={toggleFileUploadModalOpen} onSubmit={handleFileUploadLogo} />
    </>
  );
}

export default withSnackbar(MyClinic);
