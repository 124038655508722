import InvoiceDataBox from "@components/InvoiceDataBox/InvoiceDataBox";
import CustomDialog from "@components/modals/CustomDialog";
import { DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { IInvoiceData } from "invoiceData-types";
import React from "react";
import { useTranslation } from "react-i18next";

interface IChangeInvoiceDataModalProps {
  open: boolean;
  invoiceData: IInvoiceData[];
  onClose: () => void;
  handleInvoiceDataSelect: (invoiceData: IInvoiceData) => void;
}

const ChangeInvoiceDataModal: React.FC<IChangeInvoiceDataModalProps> = ({ open, invoiceData, onClose, handleInvoiceDataSelect }) => {
  if (!invoiceData) return null;
  const { t } = useTranslation();

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("invoice_data.change_data_modal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("invoice_data.change_data_modal.content_text")}</DialogContentText>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {invoiceData.map((invoice, index) => (
            <Grid item xs={12} key={invoice._id}>
              <InvoiceDataBox invoice={invoice} index={index} actions={false} selectable onClick={() => handleInvoiceDataSelect(invoice)} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default ChangeInvoiceDataModal;
