import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import FilterControls from "@components/FilterControls/FilterControls";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import PageTitle from "@components/PageTitle/PageTitle";
import { PatientStatusFilterAll } from "@components/PatientFilterTabs/PatientFilterTabs";
import { SeverityPill } from "@components/SeverityPill/SeverityPill";
import UsernameAvatar from "@components/UsernameAvatar/UsernameAvatar";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  alpha,
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { selectCurrentClinicId } from "@store/features/clinicSlice";
import {
  useAddEmployeeMutation,
  useAssignPatientToEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetAllEmployeesQuery,
  useUnassignPatientToEmployeeMutation,
} from "@store/services/employeesService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { useEditUserDetailsMutation } from "@store/services/usersService";
import { palette } from "@theme/palette";
import { API_MESSAGE_TYPE, AppRoutes, PatientStatus, SortBy, SortOrder } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { formatPhoneNumber } from "@utils/typographyUtils";
import { IEmployee } from "employees-types";
import { IPatient } from "patients-types";
import { useCallback, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmployeeCardMenu from "./fragments/EmployeeMenu";
import AddEmployeeModal from "./modals/AddEmployeeModal";
import AssignPatientToEmployeeModal from "./modals/AssignPatientToEmployeeModal";
import EditEmployeeModal from "./modals/EditEmployeeModal";

function Employees({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();
  const userRole = useContext(RoleContext);

  const selectedClinic = useSelector(selectCurrentClinicId);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [anchorElementMenu, setAnchorElementMenu] = useState<null | HTMLElement>(null);

  const [sortBy, setSortBy] = useState<SortBy>(SortBy.LAST_NAME);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);
  const [searchText, setSearchText] = useState<string>("");

  const debouncedSearchText = useDebounce(searchText, 300);

  const [isAddEmployeeModalOpen, toggleAddEmployeeModal] = useModal();
  const [isEditEmployeeModalOpen, toggleEditEmployeeModal] = useModal();
  const [isDeleteEmployeeModalOpen, toggleDeleteEmployeeModal] = useModal();
  const [isAssignPatientToEmployeeModalOpen, toggleAssignPatientToEmployeeModal] = useModal();
  const [isUnassignPatientToEmployeeModalOpen, toggleUnassignPatientToEmployeeModal] = useModal();

  const [addEmployee, { isLoading: isAddEmployeeLoading }] = useAddEmployeeMutation();
  const [editEmployee, { isLoading: isEditEmployeeLoading }] = useEditUserDetailsMutation();
  const [deleteEmployee, { isLoading: isDeleteEmployeeLoading }] = useDeleteEmployeeMutation();
  const [assignPatientToEmployee, { isLoading: isAssignPatientToEmployeeLoading }] = useAssignPatientToEmployeeMutation();
  const [unassignPatientToEmployee, { isLoading: isUnssignPatientToEmployeeLoading }] = useUnassignPatientToEmployeeMutation();

  const handleCloseEditModal = () => handleCloseModal([setSelectedEmployee], toggleEditEmployeeModal);
  const handleCloseDeleteModal = () => handleCloseModal([setSelectedEmployee], toggleDeleteEmployeeModal);
  const handleCloseAssignPatientToEmployeeModal = () => handleCloseModal([setSelectedEmployee], toggleAssignPatientToEmployeeModal);
  const handleCloseUnassignPatientToEmployeeModal = () =>
    handleCloseModal([setSelectedEmployee, setSelectedPatient], toggleUnassignPatientToEmployeeModal);

  const { employees, refetch: refetchEmployees } = useGetAllEmployeesQuery(
    {
      sortBy,
      sortOrder,
      search: debouncedSearchText,
    },
    {
      selectFromResult: ({ data }) => ({
        employees: data || [],
      }),
    }
  );

  const { patients, refetch: refetchPatients } = useGetAllPatientsQuery(
    { patientStatus: PatientStatus.ACTIVE },
    {
      selectFromResult: ({ data }) => ({
        patients: data || [],
      }),
    }
  );

  const availablePatientsToAssign = useMemo(() => {
    if (!selectedEmployee) return null;

    return patients.filter((patient) => !patient.assignedEmployees.some((employee) => employee._id === selectedEmployee._id));
  }, [patients, selectedEmployee]);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, employee: IEmployee) => {
      setAnchorElementMenu(event.currentTarget);
      setSelectedEmployee(employee);
    },
    [setAnchorElementMenu]
  );

  const handleOpenEditModal = useCallback(
    (employee: IEmployee) => {
      setSelectedEmployee(employee);
      toggleEditEmployeeModal();
    },
    [setSelectedEmployee, toggleEditEmployeeModal]
  );

  const handleOpenDeleteModal = useCallback(() => {
    toggleDeleteEmployeeModal();
    handleCloseMenu();
  }, [setSelectedEmployee, toggleDeleteEmployeeModal]);

  const handleOpenAssignPatientToEmployeeModal = useCallback(
    (employee?: IEmployee) => {
      if (employee) {
        setSelectedEmployee(employee);
        handleCloseMenu();
      }
      toggleAssignPatientToEmployeeModal();
    },
    [setSelectedEmployee, toggleAssignPatientToEmployeeModal]
  );

  const handleOpenUnassignPatientToEmployeeModal = useCallback(
    (patient: IPatient, employee: IEmployee) => {
      setSelectedPatient(patient);
      setSelectedEmployee(employee);
      toggleUnassignPatientToEmployeeModal();
    },
    [setSelectedEmployee, toggleUnassignPatientToEmployeeModal]
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorElementMenu(null);
  }, [setAnchorElementMenu]);

  const handleAddEmployee = useCallback(
    (employeeData: Partial<IEmployee>) => {
      addEmployee(employeeData)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("employee.snackbar_message.add.success"), "success");
        })
        .catch((error) => {
          if (error?.data[0].msg === API_MESSAGE_TYPE.EMAIL_IN_USE) {
            snackbarShowMessage(t("employee.snackbar_message.add.email_exists"), "info");
          } else {
            snackbarShowMessage(t("employee.snackbar_message.add.error"), "error");
          }
        })
        .finally(() => {
          toggleAddEmployeeModal();
        });
    },
    [addEmployee, snackbarShowMessage, toggleAddEmployeeModal]
  );

  const handleEditEmployee = useCallback(
    (employeeData: Partial<IEmployee>) => {
      if (!selectedEmployee) return;

      editEmployee({ clinicId: selectedClinic, userId: selectedEmployee._id, data: employeeData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("employee.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("employee.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          handleCloseEditModal();
          refetchEmployees();
        });
    },
    [editEmployee, snackbarShowMessage, handleCloseEditModal, refetchEmployees, selectedEmployee]
  );

  const handleDeleteEmployee = useCallback(() => {
    if (!selectedEmployee) return;

    deleteEmployee({ employeeId: selectedEmployee._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("employee.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("employee.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        toggleDeleteEmployeeModal();
      });
  }, [deleteEmployee, snackbarShowMessage, toggleDeleteEmployeeModal, selectedEmployee]);

  const handleAssignPatientToEmployee = useCallback(
    ({ patientBodyId }: { patientBodyId: string }) => {
      if (!selectedEmployee) return;

      assignPatientToEmployee({ employeeId: selectedEmployee._id, patientBodyId })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("employee.snackbar_message.assign_patient_to_employee.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("employee.snackbar_message.assign_patient_to_employee.error"), "error");
        })
        .finally(() => {
          handleCloseAssignPatientToEmployeeModal();
          refetchPatients();
        });
    },
    [snackbarShowMessage, refetchPatients, selectedEmployee]
  );

  const handleUnassignPatientToEmployee = useCallback(() => {
    if (!selectedEmployee || !selectedPatient) return;

    unassignPatientToEmployee({ employeeId: selectedEmployee._id, patientId: selectedPatient._id })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("employee.snackbar_message.unassign_patient_to_employee.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("employee.snackbar_message.unassign_patient_to_employee.error"), "error");
      })
      .finally(() => {
        handleCloseUnassignPatientToEmployeeModal();
        refetchPatients();
      });
  }, [snackbarShowMessage, refetchPatients, selectedEmployee]);

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_EMPLOYEES)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("employee.helmet")}</title>
      </Helmet>
      <Container>
        <PageTitle
          titleId={"employees-page-title"}
          title={t("employee.page_title")}
          rightContent={
            <Button color="secondary" variant="contained" startIcon={<AddRoundedIcon />} onClick={toggleAddEmployeeModal}>
              {t("employee.button.add_new")}
            </Button>
          }
        />
        <Paper>
          <Tabs value={PatientStatusFilterAll.ALL} onChange={() => null} variant="scrollable">
            <Tab
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="subtitle2">Wszyscy</Typography>
                  <SeverityPill color="primary">{employees.length}</SeverityPill>
                </Stack>
              }
              value={PatientStatusFilterAll.ALL}
              title={PatientStatusFilterAll.ALL}
            />
          </Tabs>
          <FilterControls {...{ searchText, setSearchText, sortBy, setSortBy, sortOrder, setSortOrder }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>{t("roles.employee")}</TableCell>
                      <TableCell>{t("personal_data.phone_number")}</TableCell>
                      <TableCell align="right">{t("employee.assigned_patients")}</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employees.length ? (
                      employees.map((employee, index) => (
                        <TableRow key={employee._id} hover sx={{ cursor: "pointer" }} onClick={() => navigate(employee._id)}>
                          <TableCell>
                            <Typography variant="caption" color="text.secondary">
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <UsernameAvatar firstname={employee.firstname} lastname={employee.lastname} email={employee.email} />
                          </TableCell>
                          <TableCell>
                            <SeverityPill>+48 {formatPhoneNumber(employee.phoneNumber)}</SeverityPill>
                          </TableCell>
                          <TableCell>
                            <AvatarGroup max={8}>
                              {patients
                                .filter((patient) => patient.assignedEmployees.find((e) => e._id === employee._id))
                                .map((patient) => (
                                  <Tooltip title={`${patient.firstname} ${patient.lastname}`} key={patient._id}>
                                    <Badge
                                      overlap="circular"
                                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                      badgeContent={
                                        <DeleteRoundedIcon
                                          sx={{
                                            cursor: "pointer",
                                            bgcolor: palette.error.main,
                                            color: palette.common.white,
                                            borderRadius: "50%",
                                            padding: 0.5,
                                            height: 22,
                                            width: 22,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenUnassignPatientToEmployeeModal(patient, employee);
                                          }}
                                        />
                                      }
                                    >
                                      <Avatar
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => navigate(`/patients/${patient._id}`)}
                                      />
                                    </Badge>
                                  </Tooltip>
                                ))}
                              <Avatar sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.5) }}>
                                <IconWithTooltip
                                  label={t("employee.menu.assign_to_patient")}
                                  icon={<AssignmentIndRoundedIcon sx={{ color: "#fff" }} />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenAssignPatientToEmployeeModal(employee);
                                  }}
                                />
                              </Avatar>
                            </AvatarGroup>
                          </TableCell>
                          <TableCell>
                            <Stack direction="row" justifyContent="end">
                              <IconWithTooltip
                                label={t("employee.button.edit")}
                                icon={<EditRoundedIcon />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenEditModal(employee);
                                }}
                              />
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpenMenu(e, employee);
                                }}
                              >
                                <MoreVertRoundedIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Typography variant="body2" color="text.secondary">
                            {t("employee.no_employees_message")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <EmployeeCardMenu
        {...{ anchorElementMenu }}
        {...{ handleOpenMenu }}
        {...{ handleCloseMenu }}
        {...{ handleOpenDeleteModal }}
        {...{ handleOpenAssignPatientToEmployeeModal }}
      />
      <AddEmployeeModal open={isAddEmployeeModalOpen} onClose={toggleAddEmployeeModal} onSubmit={handleAddEmployee} loading={isAddEmployeeLoading} />
      <EditEmployeeModal
        open={isEditEmployeeModalOpen}
        onClose={handleCloseEditModal}
        onSubmit={handleEditEmployee}
        initialValues={selectedEmployee}
        loading={isEditEmployeeLoading}
      />
      <ConfirmationModal
        open={isDeleteEmployeeModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteEmployee}
        title={t("employee.button.delete")}
        content={t("employee.delete_modal.content_text")}
        loading={isDeleteEmployeeLoading}
      />
      <AssignPatientToEmployeeModal
        open={isAssignPatientToEmployeeModalOpen}
        onClose={handleCloseAssignPatientToEmployeeModal}
        onSubmit={handleAssignPatientToEmployee}
        patients={availablePatientsToAssign}
        loading={isAssignPatientToEmployeeLoading}
      />
      <ConfirmationModal
        open={isUnassignPatientToEmployeeModalOpen}
        onClose={handleCloseUnassignPatientToEmployeeModal}
        onConfirm={handleUnassignPatientToEmployee}
        title={t("employee.unassign_patient_to_employee_modal.title")}
        content={t("employee.unassign_patient_to_employee_modal.content_text")}
        loading={isUnssignPatientToEmployeeLoading}
      />
    </>
  );
}

export default withSnackbar(Employees);
