import CustomDialog from "@components/modals/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { InvoiceStatus } from "@utils/enums";
import { getInvoiceStatusText } from "@utils/utils";
import { isAfter, startOfDay } from "date-fns";
import { IInvoice } from "invoice-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type StatusToTranslationKeyMap = {
  [key in InvoiceStatus]?: string;
};

interface IChangeInvoiceStatusProps {
  open: boolean;
  loading?: boolean;
  selectedInvoice: IInvoice;
  onSubmit: (status: InvoiceStatus) => void;
  onClose: () => void;
}
// TODO: Add translations

const ChangeInvoiceStatus: React.FC<IChangeInvoiceStatusProps> = ({ open, loading, selectedInvoice, onSubmit, onClose }) => {
  if (!selectedInvoice) return null;
  const { t } = useTranslation();

  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus>(selectedInvoice.status);

  const dialogContentText = useMemo(() => {
    const statusToTranslationKey: StatusToTranslationKeyMap = {
      [InvoiceStatus.PAID]: "Jeśli zmienisz status faktury na opłacone, to oznacza, że faktura została już opłacona przez klienta.",
      [InvoiceStatus.SENT]: "Jeśli zmienisz status faktury na wysłane, to oznacza, że faktura została wysłana do klienta.",
      [InvoiceStatus.OVERDUE]:
        "Jeśli zmienisz status faktury na zaległe, to oznacza, że termin płatności faktury minął i nie została ona opłacona przez klienta.",
      [InvoiceStatus.DRAFT]:
        "Jeśli zmienisz status faktury na szkic, to oznacza, że faktura jest w trakcie przygotowywania i nie została jeszcze wysłana do klienta.",
    };

    return t(statusToTranslationKey[invoiceStatus]);
  }, [invoiceStatus, t]);

  useEffect(() => {
    setInvoiceStatus(selectedInvoice.status);
  }, [selectedInvoice]);

  const today = startOfDay(new Date());
  const isOverdue = isAfter(today, startOfDay(new Date(selectedInvoice.paymentDate)));

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>{t("Zmień status faktury")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
        <ToggleButtonGroup
          color="primary"
          fullWidth
          exclusive
          value={invoiceStatus}
          onChange={(event: React.MouseEvent<HTMLElement>, newStatus: InvoiceStatus | null) => {
            if (newStatus !== null) setInvoiceStatus(newStatus);
          }}
          sx={{ mt: 3 }}
        >
          <ToggleButton value={InvoiceStatus.PAID} color="success">
            {getInvoiceStatusText(InvoiceStatus.PAID)}
          </ToggleButton>
          {isOverdue && (
            <ToggleButton value={InvoiceStatus.OVERDUE} color="error">
              {getInvoiceStatusText(InvoiceStatus.OVERDUE)}
            </ToggleButton>
          )}
          {!isOverdue && (
            <ToggleButton value={InvoiceStatus.SENT} color="warning">
              {getInvoiceStatusText(InvoiceStatus.SENT)}
            </ToggleButton>
          )}
          {!isOverdue && (
            <ToggleButton value={InvoiceStatus.OVERDUE} color="error">
              {getInvoiceStatusText(InvoiceStatus.OVERDUE)}
            </ToggleButton>
          )}
          {!isOverdue && (
            <ToggleButton value={InvoiceStatus.DRAFT} color="info">
              {getInvoiceStatusText(InvoiceStatus.DRAFT)}
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {t("cancel")}
        </Button>
        <LoadingButton loading={loading} onClick={() => onSubmit(invoiceStatus)} variant="contained" autoFocus color="secondary">
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default ChangeInvoiceStatus;
