export enum AppRoutes {
  Dashboard = "/",
  MyClinic = "/my-clinic",
  Reports = "/reports",
  PatientReports = "/reports/:patientId",
  Employees = "/employees",
  EmployeeDetails = "/employees/:employeeId",
  Appointments = "/appointments",
  ActivityLog = "/activity-log",
  Patients = "/patients",
  Invoices = "/invoices",
  Expenses = "/expenses",
  CreateInvoice = "/invoices/create/:patientId",
  PreviewInvoice = "/invoices/preview/:invoiceId",
  PatientDetails = "/patients/:patientId",
  PatientDiagnosis = "/patients/:patientId/diagnosis",
  PatientHomeworks = "/patients/:patientId/homeworks",
  Settings = "/settings",
  VerifyAssignment = "/verify-assignment",
  VerifyAssignmentFailed = "/notifications/authentication-failed",
  PasswordReset = "/password-reset",
  PasswordResetSuccess = "/password-reset/success",
  ChangePassword = "/password-change",
  ChangePasswordSuccess = "/password-change/success",
  ConfirmRegistration = "/confirm-registration",
  ConfirmRegistrationSuccess = "/confirm-registration/success",
  ConfirmRegistrationPatient = "/confirm-registration/patient",
  ConfirmRegistrationPatientSuccess = "/confirm-registration/patient/success",
  SignUpSuccess = "/signup/success",

  Login = "/signin",
  Register = "/signup",
  Error = "/error",
}

export enum Sex {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum NavigateAction {
  PREV = "PREV",
  NEXT = "NEXT",
  TODAY = "TODAY",
  DATE = "DATE",
}

export enum PatientStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AppointmentStatus {
  PRESENT = "PRESENT",
  EXCUSED = "EXCUSED",
  NOT_EXCUSED = "NOT_EXCUSED",
  PENDING = "PENDING",
}

export enum InvoiceStatus {
  PAID = "PAID",
  SENT = "SENT",
  OVERDUE = "OVERDUE",
  DRAFT = "DRAFT",
}

export enum SortOrder {
  ASC = 1,
  DESC = -1,
}

export enum SortBy {
  FIRST_NAME = "firstname",
  LAST_NAME = "lastname",
  DATE = "date",
  TOTAL_INCOME = "totalIncome",
}

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export enum ClinicNotificationConfig {
  UPCOMING_APPOINTMENT = "upcomingAppointment",
  HOMEWORK_REMINDER = "homeworkReminder",
  ADDED_HOMEWORK = "addedHomeWork",
}

export enum NotificationMethod {
  EMAIL = "email",
  PUSH = "push",
  SMS = "sms",
}

export enum SendBeforeHours {
  HOURS_24 = 24,
  HOURS_48 = 48,
  HOURS_72 = 72,
}
export enum API_MESSAGE_TYPE {
  EMAIL_IN_USE = "EMAIL_IN_USE",
}
