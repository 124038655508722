import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import BookRoundedIcon from "@mui/icons-material/BookRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { AppRoutes } from "@utils/enums";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

interface IMenuSection {
  id: string;
  title?: string;
  items: IMenuItem[];
}

interface IMenuItem {
  id: string;
  title: string;
  path: string;
  icon?: React.ReactNode;
}

const MenuItemsConfig = (): IMenuSection[] => {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);

  return [
    {
      id: "main",
      items: [
        {
          id: "dashboard",
          title: t("menu.dashboard"),
          path: AppRoutes.Dashboard,
          icon: <DashboardRoundedIcon />,
        },
      ],
    },
    {
      id: "clinic-management",
      title: t("menu.clinic_management_section"),
      items: [
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_CLINICS)
          ? [
              {
                id: "my-clinic",
                title: t("menu.my_clinic"),
                path: AppRoutes.MyClinic,
                icon: <HomeWorkRoundedIcon />,
              },
            ]
          : []),
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_EMPLOYEES)
          ? [
              {
                id: "employees",
                title: t("menu.employees"),
                path: AppRoutes.Employees,
                icon: <BadgeRoundedIcon />,
              },
            ]
          : []),

        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_APPOINTMENTS)
          ? [
              {
                id: "appointments",
                title: t("menu.appointments"),
                path: AppRoutes.Appointments,
                icon: <CalendarMonthRoundedIcon />,
              },
            ]
          : []),
      ],
    },
    {
      id: "patients",
      title: t("menu.patients_section"),
      items: [
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_PATIENTS)
          ? [
              {
                id: "patients",
                title: t("menu.patients"),
                path: AppRoutes.Patients,
                icon: <PeopleAltRoundedIcon />,
              },
            ]
          : []),
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_ACTIVITY_LOG)
          ? [
              {
                id: "activity-log",
                title: t("menu.activity_log"),
                path: AppRoutes.ActivityLog,
                icon: <BookRoundedIcon />,
              },
            ]
          : []),
      ],
    },
    {
      id: "financials",
      title: t("menu.financials_section"),
      items: [
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_REPORTS)
          ? [
              {
                id: "reports",
                title: t("menu.reports"),
                path: AppRoutes.Reports,
                icon: <AssessmentRoundedIcon />,
              },
            ]
          : []),
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_INVOICE)
          ? [
              {
                id: "invoices",
                title: t("menu.invoices"),
                path: AppRoutes.Invoices,
                icon: <DescriptionRoundedIcon />,
              },
            ]
          : []),
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_EXPENSES)
          ? [
              {
                id: "expenses",
                title: t("menu.expenses"),
                path: AppRoutes.Expenses,
                icon: <SavingsRoundedIcon />,
              },
            ]
          : []),
      ],
    },
    {
      id: "settings",
      title: t("menu.settings_section"),
      items: [
        ...(rbacCheck(userRole, RBACActions.MAIN_MENU_SETTINGS)
          ? [
              {
                id: "settings",
                title: t("menu.settings"),
                path: AppRoutes.Settings,
                icon: <SettingsRoundedIcon />,
              },
            ]
          : []),
      ],
    },
  ];
};

export default MenuItemsConfig;
