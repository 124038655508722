import Iconify from "@components/Iconify/Iconify";
import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import { useThemeSettings } from "@context/ThemeSettingsProvider";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import TextFieldsRoundedIcon from "@mui/icons-material/TextFieldsRounded";
import { Badge, Box, Button, Divider, Grid, IconButton, Stack, SwipeableDrawer, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/system";
import { PrimaryPaletteKey, primaryPalettes } from "@theme/palette";
import React, { KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const colors: PrimaryPaletteKey[] = Object.keys(primaryPalettes) as PrimaryPaletteKey[];

const fonts = [
  { displayName: "Public Sans", value: "Public Sans, sans-serif" },
  { displayName: "Inter", value: "Inter, sans-serif" },
  { displayName: "Nunito Sans", value: "Nunito Sans, sans-serif" },
  { displayName: "DM Sans", value: "DM Sans, sans-serif" },
];

interface IThemeSettingsDrawerProps {
  icon: React.ReactNode;
  isHeader?: boolean;
}

const ThemeSettingsDrawer: React.FC<IThemeSettingsDrawerProps> = ({ icon, isHeader }) => {
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const theme = useTheme();
  const { settings, updateSettings } = useThemeSettings();

  const isSettingsChanged = settings.primaryColor !== "PRIMARY" || settings.primaryFont !== "Nunito Sans, sans-serif";

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const toggleDrawer = useCallback(
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (event.type === "keydown" && ((event as KeyboardEvent).key === "Tab" || (event as KeyboardEvent).key === "Shift")) {
        return;
      }
      setDrawerOpen(open);
    },
    []
  );

  const handleColorChange = useCallback(
    (colorName: PrimaryPaletteKey) => {
      updateSettings({ primaryColor: colorName });
    },
    [updateSettings]
  );

  const handleFontChange = useCallback(
    (fontValue: string) => {
      updateSettings({ primaryFont: fontValue });
    },
    [updateSettings]
  );

  const handleReset = useCallback(() => {
    updateSettings({
      primaryColor: "PRIMARY" as PrimaryPaletteKey,
      primaryFont: "Nunito Sans, sans-serif",
    });
  }, [updateSettings]);

  const handleFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <>
      <Badge
        color="primary"
        variant="dot"
        invisible={!isSettingsChanged}
        sx={{
          "& .MuiBadge-dot": {
            backgroundColor: theme.palette.error.main,
            position: "absolute",
            top: 10,
            right: 0,
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <IconButton color="default" sx={{ width: 40, height: isHeader && 40, padding: "0px" }} onClick={toggleDrawer(true)}>
          {icon}
        </IconButton>
      </Badge>

      <SwipeableDrawer
        onOpen={() => null}
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            borderRadius: 0,
            width: isDesktop ? 350 : 300,
          },
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ mb: 1 }}>
            <Typography variant="h6">{t("theme_settings.settings")}</Typography>
            <Stack direction="row" spacing={0}>
              <IconWithTooltip
                label={isFullscreen ? t("theme_settings.exit_fullscreen") : t("theme_settings.fullscreen")}
                onClick={handleFullscreen}
                icon={
                  <Iconify
                    icon={isFullscreen ? "eva:collapse-fill" : "eva:expand-fill"}
                    sx={{ color: isFullscreen ? theme.palette.primary.main : "inherit" }}
                  />
                }
              />
              <Badge
                color="primary"
                variant="dot"
                invisible={!isSettingsChanged}
                sx={{
                  "& .MuiBadge-dot": {
                    display: !isSettingsChanged ? "none" : "block",
                    backgroundColor: theme.palette.error.main,
                    position: "absolute",
                    top: 10,
                    right: -3,
                    transform: "translate(-50%, -50%)",
                  },
                }}
              >
                <IconWithTooltip
                  label={t("theme_settings.reset")}
                  onClick={handleReset}
                  icon={<Iconify icon="eva:refresh-outline" sx={{ color: isSettingsChanged ? theme.palette.primary.main : "inherit" }} />}
                />
              </Badge>
              <IconWithTooltip label={t("theme_settings.close")} onClick={toggleDrawer(false)} icon={<Iconify icon="eva:close-fill" />} />
            </Stack>
          </Stack>
          <Divider sx={{ borderStyle: "dashed", mb: 2 }} />
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }} gutterBottom>
              {t("theme_settings.pick_color_schema")}
            </Typography>
            <Box sx={{ p: 1 }}>
              <Grid container spacing={2}>
                {colors.map((colorName) => (
                  <Grid item xs={4} key={colorName}>
                    <Button
                      onClick={() => handleColorChange(colorName)}
                      sx={{
                        width: "100%",
                        height: 60,
                        backgroundColor: settings.primaryColor === colorName ? alpha(primaryPalettes[colorName].main, 0.12) : "transparent",
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: theme.shadows[4],
                        "&:hover": {
                          backgroundColor: alpha(primaryPalettes[colorName].light, 0.25),
                        },
                      }}
                    >
                      <SpaceDashboardRoundedIcon sx={{ color: primaryPalettes[colorName].main }} />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }} gutterBottom>
              {t("theme_settings.pick_font")}
            </Typography>
            <Box sx={{ p: 1 }}>
              <Grid container spacing={2}>
                {fonts.map(({ displayName, value }) => (
                  <Grid item xs={6} key={value}>
                    <Button
                      onClick={() => handleFontChange(value)}
                      sx={{
                        width: "100%",
                        height: 100,
                        backgroundColor: settings.primaryFont === value ? alpha(theme.palette.primary.main, 0.12) : "transparent",
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: theme.shadows[4],
                        "&:hover": {
                          backgroundColor: alpha(theme.palette.primary.light, 0.25),
                        },
                      }}
                    >
                      <Stack direction="column" alignItems="center">
                        <TextFieldsRoundedIcon sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="subtitle2">{displayName}</Typography>
                      </Stack>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ThemeSettingsDrawer;
