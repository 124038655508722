import PatientAutocomplete from "@components/PatientAutocomplete/PatientAutocomplete";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfMonth, startOfMonth } from "date-fns";
import { IPatient } from "patients-types";
import React from "react";
import { InvoiceFilter } from "../Invoices";

// TODO: Add translations
interface IInvoiceFilterPanelProps {
  filters: InvoiceFilter;
  patients: IPatient[];
  setFilters: React.Dispatch<React.SetStateAction<InvoiceFilter>>;
}

const InvoiceFilterPanel: React.FC<IInvoiceFilterPanelProps> = ({ filters, patients, setFilters }) => {
  const handleFilterChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [field]: event.target.value,
    });
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const startDate = startOfMonth(date);
      const endDate = endOfMonth(date);
      setFilters({
        ...filters,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
    }
  };

  return (
    <Grid container sx={{ p: 3 }} spacing={3}>
      <Grid item xs={12} sm={4}>
        <PatientAutocomplete
          patients={patients}
          onPatientChange={(patient) =>
            setFilters({
              ...filters,
              patientId: patient?._id,
            })
          }
          placeholder={"Filtruj po pacjencie"}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePicker
          label={"Miesiąc i rok wystawienia"}
          views={["year", "month"]}
          value={filters.startDate ? new Date(filters.startDate) : new Date()}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label={"Numer faktury"}
          variant="outlined"
          InputProps={{
            endAdornment: <DescriptionRoundedIcon color="action" />,
          }}
          value={filters.invoiceNumber}
          onChange={handleFilterChange("invoiceNumber")}
        />
      </Grid>
    </Grid>
  );
};

export default InvoiceFilterPanel;
