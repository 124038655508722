import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import Logo from "@components/Logo/Logo";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Avatar, Box, Divider, Drawer, ListItemIcon, MenuItem, Popover, Stack, SwipeableDrawer, Typography, useTheme } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { removeClinicId } from "@store/features/clinicSlice";
import { IClinic } from "clinics-types";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import { IUserDetails } from "user-types";
import { NAV_WIDTH } from "../Header/Header";
import MenuItems from "./fragments/MenuItems";
import MenuItemsConfig from "./fragments/MenuItemsConfig";
import { logOut } from "@store/features/authSlice";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";

const StyledSidebar = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const ScrollableMenu = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  flexGrow: 1,
  "&::-webkit-scrollbar": {
    width: 7,
  },
  "&::-webkit-scrollbar-thumb": {
    background: alpha(theme.palette.primary.main, 0.12),
    borderRadius: 20,
  },
}));

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: 10,
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2, 1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

interface IAppSidebarProps {
  userDetails: IUserDetails;
  currentClinic: IClinic;
  openNav: boolean;
  onCloseNav: () => void;
}

const AppSidebar: React.FC<IAppSidebarProps> = ({ userDetails, currentClinic, openNav, onCloseNav }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  const handleOpen = (event: SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <Box sx={{ px: 3, pb: 3, pt: 4, display: "inline-flex", position: "sticky", top: 0, zIndex: 1 }}>
        <Logo sx={{ position: "static" }} />
      </Box>
      <Box sx={{ mb: 0, mx: 2, position: "sticky", top: 64, zIndex: 1 }}>
        <StyledAccount>
          <Stack direction="row" alignItems="center">
            <Avatar src={currentClinic?.files[0]?.url} />
            <Box sx={{ ml: 1, maxWidth: 150 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }} noWrap>
                {`${userDetails?.email} `}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                {currentClinic?.name}
              </Typography>
            </Box>
          </Stack>
          <IconWithTooltip label={t("menu.sidebar_menu")} icon={<MoreVertRoundedIcon />} onClick={handleOpen} />
        </StyledAccount>
        <Divider sx={{ borderStyle: "dashed" }} />
      </Box>

      <ScrollableMenu>
        <MenuItems data={MenuItemsConfig()} />
      </ScrollableMenu>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={0.75} sx={{ py: 1 }}>
          <MenuItem onClick={() => dispatch(removeClinicId())}>
            <ListItemIcon>
              <MeetingRoomRoundedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t("menu.clinic_manage")}
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => dispatch(logOut())}>
            <ListItemIcon>
              <LogoutRoundedIcon fontSize="small" color="error" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap sx={{ color: theme.palette.error.main }}>
              {t("logout")}
            </Typography>
          </MenuItem>
        </Stack>
      </Popover>
    </>
  );

  return (
    <StyledSidebar
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
              borderRadius: 0,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <SwipeableDrawer
          onOpen={() => null}
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH, borderRadius: 0 },
          }}
        >
          {renderContent}
        </SwipeableDrawer>
      )}
    </StyledSidebar>
  );
};

export default AppSidebar;
