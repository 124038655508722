import { differenceInMonths, differenceInYears, format, formatDistanceToNow } from "date-fns";
import { enUS, pl } from "date-fns/locale";
import i18n from "i18next";
import { capitalizeWord } from "./typographyUtils";
import { t } from "i18next";

const localeMap: Record<string, Locale> = {
  en: enUS,
  pl: pl,
};

export const locale = localeMap[i18n.language] || pl;

export function fToNow(date: Date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: locale,
      })
    : "";
}

export function formatTime(timeString: string) {
  const [hours, minutes] = timeString.split(":").slice(0, 2);
  return `${hours}:${minutes}`;
}

export const getFormattedTime = (date: Date): string => {
  return format(date, "HH:mm");
};

export function formatDate(date: Date, formatStr: string): string {
  if (!(date instanceof Date && !isNaN(date.getTime()))) return "";

  return capitalizeWord(format(date, formatStr, { locale }));
}

export function formatRelativeTime(date: Date): string {
  return capitalizeWord(formatDistanceToNow(date, { addSuffix: true, locale }));
}

const getMonthDeclension = (months: number): string => {
  if (months === 1) {
    return t("month");
  } else if (months >= 2 && months <= 4) {
    return t("months_1");
  } else {
    return t("months_2");
  }
};

export const calculateAge = (dateOfBirth: Date): string => {
  const today = new Date();
  const ageInYears = differenceInYears(today, dateOfBirth);

  if (ageInYears < 1) {
    const ageInMonths = differenceInMonths(today, dateOfBirth);
    const monthDeclension = getMonthDeclension(ageInMonths);
    return `${ageInMonths} ${monthDeclension}`;
  }

  return `${ageInYears}`;
};
