import CustomDialog from "@components/modals/CustomDialog";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Form } from "@theme/styled-components/StyledForm";
import { FieldArray, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { addEditAppointmentTypeValidationSchema } from "src/validations/appointmentTypesValidation";

interface IAddAppointmentTypeModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: IAppointmentType) => void;
}

interface IAppointmentType {
  name: string;
  pricing: { duration: number | null; cost: number | null }[];
}

const AddAppointmentTypeModal: React.FC<IAddAppointmentTypeModalProps> = ({ open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const initialValues: IAppointmentType = {
    name: "",
    pricing: [{ duration: null, cost: null }],
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={addEditAppointmentTypeValidationSchema} onSubmit={onSubmit}>
      {({ errors, touched, values, setFieldValue }) => (
        <CustomDialog open={open} onClose={onClose}>
          <DialogTitle>{t("appointment_types.add_modal.title")}</DialogTitle>
          <Form>
            <DialogContent>
              <DialogContentText>{t("appointment_types.add_modal.content_text")}</DialogContentText>
              <Grid container sx={{ my: 1 }} spacing={2} id="add-appointment-type-modal">
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label={t("appointment_types.add_modal.name")}
                    onChange={(event) => {
                      setFieldValue("name", event.target.value);
                    }}
                    helperText={touched.name && errors.name ? t(errors.name as string) : t("appointment_types.add_modal.name_helper_text")}
                    error={touched.name && !!errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="pricing"
                    render={(arrayHelpers) => (
                      <>
                        {values.pricing.map((price, index) => (
                          <Stack direction="row" alignItems="center" key={index} spacing={2} sx={{ mb: 2 }}>
                            <TextField
                              name={`pricing.${index}.duration`}
                              fullWidth
                              label={t("appointment_types.add_modal.duration")}
                              type="number"
                              inputProps={{
                                min: 1,
                                max: 480,
                              }}
                              value={price.duration ?? ""}
                              onChange={(event) => {
                                const value = event.target.value;
                                setFieldValue(`pricing.${index}.duration`, value === "" ? null : Number(value));
                              }}
                              helperText={
                                // @ts-expect-error
                                touched.pricing?.[index]?.duration && errors.pricing?.[index]?.duration
                                  ? t(
                                      // @ts-expect-error
                                      errors.pricing[index].duration
                                    )
                                  : t("appointment_types.validation.duration_helper_text")
                              }
                              // @ts-expect-error
                              error={touched.pricing?.[index]?.duration && Boolean(errors.pricing?.[index]?.duration)}
                            />
                            <TextField
                              name={`pricing.${index}.cost`}
                              fullWidth
                              label={t("appointment_types.cost")}
                              inputProps={{
                                min: 0,
                                max: 10000,
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                              }}
                              value={price.cost ?? ""}
                              onChange={(event) => {
                                const value = event.target.value;
                                setFieldValue(`pricing.${index}.cost`, value === "" ? null : Number(value));
                              }}
                              type="number"
                              helperText={
                                // @ts-expect-error
                                touched.pricing?.[index]?.cost && errors.pricing?.[index]?.cost
                                  ? t(
                                      // @ts-expect-error
                                      errors.pricing[index].cost
                                    )
                                  : t("appointment_types.validation.cost_helper_text")
                              }
                              // @ts-expect-error
                              error={touched.pricing?.[index]?.cost && Boolean(errors.pricing?.[index]?.cost)}
                            />
                            <Grid item xs={1}>
                              <IconButton onClick={() => arrayHelpers.remove(index)} sx={{ mt: -2.5 }}>
                                <ClearRoundedIcon />
                              </IconButton>
                            </Grid>
                          </Stack>
                        ))}
                        <Button
                          startIcon={<AddRoundedIcon />}
                          type="button"
                          variant="contained"
                          color="secondary"
                          onClick={() => arrayHelpers.push({ duration: null, cost: null })}
                        >
                          {t("appointment_types.add_new_price")}
                        </Button>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
              <LoadingButton loading={loading} type="submit" variant="contained" color="secondary" id="add-appointment-type-modal-confirm-button">
                {t("submit")}
              </LoadingButton>
            </DialogActions>
          </Form>
        </CustomDialog>
      )}
    </Formik>
  );
};

export default AddAppointmentTypeModal;
