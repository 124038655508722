import { createApi } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "@utils/urlsUtils";
import axiosBaseQuery from "../utils/axiosBaseQuery";
import { IAppointmentType } from "appointmentTypes.types";

enum TagTypes {
  APPOINTMENT_TYPES = "APPOINTMENT_TYPES",
}

export const appointmentTypesApi = createApi({
  reducerPath: "appointmentTypesApi",
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl, isTokenRequired: true, includeClinicId: true }),
  tagTypes: [TagTypes.APPOINTMENT_TYPES],
  endpoints: (build) => ({
    getAppointmentTypes: build.query<IAppointmentType[], unknown>({
      query: () => ({
        method: "GET",
        url: `settings/appointment-types`,
      }),
      providesTags: [TagTypes.APPOINTMENT_TYPES],
    }),
    addAppointmentType: build.mutation<IAppointmentType, { newAppointmentType: Partial<IAppointmentType> }>({
      query: ({ newAppointmentType }) => ({
        method: "POST",
        url: `settings/appointment-types`,
        data: newAppointmentType,
      }),
      invalidatesTags: [TagTypes.APPOINTMENT_TYPES],
    }),
    editAppointmentType: build.mutation<IAppointmentType, { typeId: string; updatedAppointmentType: Partial<IAppointmentType> }>({
      query: ({ typeId, updatedAppointmentType }) => ({
        method: "PATCH",
        url: `settings/appointment-types/${typeId}`,
        data: updatedAppointmentType,
      }),
      invalidatesTags: [TagTypes.APPOINTMENT_TYPES],
    }),
  }),
});

export const { useGetAppointmentTypesQuery, useAddAppointmentTypeMutation, useEditAppointmentTypeMutation } = appointmentTypesApi;

export const appointmentTypesApiReducer = {
  [appointmentTypesApi.reducerPath]: appointmentTypesApi.reducer,
};
