import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions } from "@auth/rbac-rules";
import PageTitle from "@components/PageTitle/PageTitle";
import ToggleAppointmentStatusModal from "@components/modals/Appointments/ToggleAppointmentStatusModal";
import ConfirmationModal from "@components/modals/ConfirmationModal/ConfirmationModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import useDebounce from "@hooks/useDebounce";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Container, Grid } from "@mui/material";
import {
  useAddActivityLogMutation,
  useDeleteActivityLogMutation,
  useEditActivityLogMutation,
  useGetAllActivityLogsQuery,
} from "@store/services/activityLogService";
import { useGetAllAppointmentsQuery, useToggleAppointmentStatusMutation } from "@store/services/appointmentsService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { AppRoutes, AppointmentStatus, PatientStatus } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { formatDate } from "@utils/timeUtils";
import { IActivityLog } from "activityLog.types";
import { IAppointment } from "appointment.types";
import { addMonths, compareDesc, endOfMonth, parseISO, startOfMonth } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActivityLogTable from "./fragments/ActivityLogTable";
import PatientsList from "./fragments/PatientsList";
import AddLogModal from "./modals/AddLogModal";
import EditLogModal from "./modals/EditLogModal";

const formatTimeRange = (startDate: Date, endDate: Date) => {
  const start = formatDate(startDate, "HH:mm");
  const end = formatDate(endDate, "HH:mm");
  return `${start} - ${end}`;
};

const ActivityLog = ({ snackbarShowMessage }: SnackbarProps) => {
  const { t } = useTranslation();

  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const [isAddLogModalOpen, toggleAddLogModal] = useModal();
  const [isEditLogModalOpen, toggleEditLogModal] = useModal();
  const [isAppointmentStatusModalOpen, toggleAppointmentStatusModalOpen] = useModal();
  const [isDeleteLogModalOpen, toggleDeleteLogModal] = useModal();

  const handleCloseEditModal = () => handleCloseModal([setSelectedActivityLog], toggleEditLogModal);
  const handleCloseDeleteModal = () => handleCloseModal([setSelectedActivityLogId], toggleDeleteLogModal);
  const handleCloseToggleAppointmentStatusModal = () => handleCloseModal([setSelectedActivityLogId], toggleAppointmentStatusModalOpen);

  const [addActivityLog, { isLoading: isAddActivityLogLoading }] = useAddActivityLogMutation();
  const [editActivityLog, { isLoading: isEditActivityLogLoading }] = useEditActivityLogMutation();
  const [deleteActivityLog, { isLoading: isDeleteActivityLogLoading }] = useDeleteActivityLogMutation();
  const [toggleAppointmentStatus, { isLoading: isToggleAppointmentStatusLoading }] = useToggleAppointmentStatusMutation();

  const [selectedPatient, setSelectedPatient] = useState<string>("");
  const [selectedActivityLog, setSelectedActivityLog] = useState<Partial<IActivityLog> | null>(null);
  const [selectedActivityLogId, setSelectedActivityLogId] = useState<string | null>(null);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [searchText, setSearchText] = useState<string>("");

  const debouncedSearchText = useDebounce(searchText, 300);

  const handlePreviousMonth = useCallback(() => {
    setCurrentDate((prevDate) => addMonths(prevDate, -1));
  }, []);

  const handleNextMonth = useCallback(() => {
    setCurrentDate((prevDate) => addMonths(prevDate, 1));
  }, []);

  const { patients } = useGetAllPatientsQuery(
    {
      patientStatus: PatientStatus.ACTIVE,
      search: debouncedSearchText,
    },
    {
      selectFromResult: ({ data }) => ({
        patients: data || [],
      }),
    }
  );

  const { appointments } = useGetAllAppointmentsQuery(
    {
      patientId: selectedPatient,
      start: startOfMonth(currentDate).toISOString(),
      from: endOfMonth(currentDate).toISOString(),
    },
    {
      selectFromResult: ({ data }) => ({
        appointments: data || [],
      }),
      skip: !selectedPatient,
    }
  );

  const { activityLogs, refetch: refetchActivityLogs } = useGetAllActivityLogsQuery(
    {
      patientId: selectedPatient,
      month: formatDate(currentDate, "yyyy-MM"),
    },
    {
      selectFromResult: ({ data }) => ({
        activityLogs: data || [],
      }),
      skip: !selectedPatient,
    }
  );

  const handleOpenEditModal = useCallback(
    (activityLogData: Partial<IActivityLog>) => {
      setSelectedActivityLog(activityLogData);
      toggleEditLogModal();
    },
    [setSelectedActivityLog, toggleEditLogModal]
  );

  const handleOpenToggleAppointmentStatusModal = useCallback(
    (activityLogData: Partial<IActivityLog>) => {
      setSelectedActivityLog(activityLogData);
      toggleAppointmentStatusModalOpen();
    },
    [setSelectedActivityLog, toggleAppointmentStatusModalOpen]
  );

  const handleOpenDeleteModal = useCallback(
    (activityLogId: string) => {
      setSelectedActivityLogId(activityLogId);
      toggleDeleteLogModal();
    },
    [setSelectedActivityLogId, toggleDeleteLogModal]
  );

  const handleAddActivityLog = useCallback(
    (logData: Partial<IActivityLog>) => {
      addActivityLog(logData)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("activity_log.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("activity_log.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddLogModal();
        });
    },
    [addActivityLog, snackbarShowMessage, toggleAddLogModal, t]
  );

  const handleEditActivityLog = useCallback(
    (logData: Partial<IActivityLog>) => {
      if (!selectedActivityLog) return;
      editActivityLog({ activityLogId: selectedActivityLog._id, data: logData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("activity_log.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("activity_log.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          toggleEditLogModal();
        });
    },
    [editActivityLog, snackbarShowMessage, toggleEditLogModal, selectedActivityLog, t]
  );

  const handleDeleteActivityLog = useCallback(() => {
    if (!selectedActivityLogId) return;
    deleteActivityLog({ activityLogId: selectedActivityLogId })
      .unwrap()
      .then(() => {
        snackbarShowMessage(t("activity_log.snackbar_message.delete.success"), "success");
      })
      .catch(() => {
        snackbarShowMessage(t("activity_log.snackbar_message.delete.error"), "error");
      })
      .finally(() => {
        toggleDeleteLogModal();
      });
  }, [deleteActivityLog, snackbarShowMessage, toggleDeleteLogModal, selectedActivityLogId, t]);

  const handleToggleAppointmentStatus = useCallback(
    (status: AppointmentStatus) => {
      if (!selectedActivityLog) return;
      toggleAppointmentStatus({ appointmentId: selectedActivityLog?.appointmentId._id, data: { appointmentStatus: status } })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments_toggle_status_success"), "success");
          refetchActivityLogs();
        })
        .catch(() => {
          snackbarShowMessage(t("appointments_toggle_status_error"), "error");
        })
        .finally(() => {
          handleCloseToggleAppointmentStatusModal();
        });
    },
    [toggleAppointmentStatus, snackbarShowMessage, selectedActivityLog, refetchActivityLogs, handleCloseToggleAppointmentStatusModal, t]
  );

  const getAppointmentsData = (appointments: IAppointment[], activityLogs: IActivityLog[], filter = false) => {
    const activityLogAppointmentIds = filter ? new Set(activityLogs.map((log) => log.appointmentId && log.appointmentId._id)) : new Set();

    return appointments
      .filter((appointment) => !filter || !activityLogAppointmentIds.has(appointment._id))
      .map((appointment) => {
        const startDate = parseISO(appointment.startDate);
        const endDate = parseISO(appointment.endDate);

        return {
          label: appointment.title,
          date: startDate,
          time: formatTimeRange(startDate, endDate),
          id: appointment._id,
        };
      });
  };

  const appointmentsDataForAdd = useMemo(() => getAppointmentsData(appointments, activityLogs, true), [appointments, activityLogs]);

  const appointmentsDataForEdit = useMemo(() => getAppointmentsData(appointments, activityLogs, false), [appointments, activityLogs]);

  useEffect(() => {
    if (!!patients.length && !selectedPatient) {
      setSelectedPatient(patients[0]._id);
    }
  }, [patients, selectedPatient]);

  const sortedActivityLogs = useMemo(() => {
    return (
      activityLogs?.slice().sort((a, b) => {
        if (!a.appointmentId || !b.appointmentId) return 0;

        return compareDesc(parseISO(a.appointmentId.endDate), parseISO(b.appointmentId.endDate));
      }) || []
    );
  }, [activityLogs]);

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_ACTIVITY_LOG)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("activity_log.helmet")}</title>
      </Helmet>
      <Container>
        <PageTitle
          gap={5}
          title={t("activity_log.title")}
          rightContent={
            <Button color="secondary" variant="contained" startIcon={<AddRoundedIcon />} onClick={toggleAddLogModal} disabled={!patients.length}>
              {t("activity_log.add.button")}
            </Button>
          }
        />
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={3}>
              <PatientsList
                searchTerm={searchText}
                handleSearchChange={setSearchText}
                filteredPatients={patients}
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <ActivityLogTable
                currentDate={currentDate}
                handlePreviousMonth={handlePreviousMonth}
                handleNextMonth={handleNextMonth}
                activityLogs={activityLogs}
                sortedActivityLogs={sortedActivityLogs}
                handleOpenToggleAppointmentStatusModal={handleOpenToggleAppointmentStatusModal}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <AddLogModal
        patientId={selectedPatient}
        appointments={appointmentsDataForAdd}
        open={isAddLogModalOpen}
        loading={isAddActivityLogLoading}
        date={currentDate}
        onClose={toggleAddLogModal}
        onSubmit={handleAddActivityLog}
        handleNextMonthClick={handleNextMonth}
        handlePrevMonthClick={handlePreviousMonth}
      />
      <EditLogModal
        open={isEditLogModalOpen}
        loading={isEditActivityLogLoading}
        appointments={appointmentsDataForEdit}
        initialValues={selectedActivityLog}
        onClose={handleCloseEditModal}
        onSubmit={handleEditActivityLog}
      />
      <ToggleAppointmentStatusModal
        selectedAppointment={selectedActivityLog?.appointmentId}
        open={isAppointmentStatusModalOpen}
        onClose={handleCloseToggleAppointmentStatusModal}
        onSubmit={handleToggleAppointmentStatus}
        loading={isToggleAppointmentStatusLoading}
      />
      <ConfirmationModal
        open={isDeleteLogModalOpen}
        title={t("activity_log.delete.title")}
        content={t("activity_log.delete.content_text")}
        loading={isDeleteActivityLogLoading}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteActivityLog}
      />
    </>
  );
};

export default withSnackbar(ActivityLog);
