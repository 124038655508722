import IconWithTooltip from "@components/IconWithTooltip/IconWithTooltip";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Box, Card, Divider, Grid, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { palette } from "@theme/palette";
import { getAppointmentStatusText } from "@utils/utils";
import { IClinic } from "clinics-types";
import { IInvoiceItem, IInvoiceResults } from "invoice-types";
import { IInvoiceData } from "invoiceData-types";
import { IPatient } from "patients-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SellerInfo from "./fragments/SellerInfo";

// TODO: Add translations
interface IInvoiceSettingsProps {
  paymentDate: Date | null;
  invoiceNumber: string;
  currentClinic: IClinic;
  invoiceDraftData: IInvoiceResults;
  selectedInvoiceData: IInvoiceData;
  selectedAcquirerData: IPatient;
  dateRange: { start: Date; end: Date };
  hasPatientInvoiceData: boolean;
  setInvoiceNumber: (value: string) => void;
  handleDateChange: (field: string, value: Date) => void;
  toggleChangeInvoiceDataModal: () => void;
  toggleChooseAcquirerDataModal: () => void;
  handlePaymentDateChange: (date: Date | null) => void;
  handleSuggestInvoiceNumber: () => void;
}

const InvoiceSettings: React.FC<IInvoiceSettingsProps> = ({
  paymentDate,
  invoiceNumber,
  currentClinic,
  selectedInvoiceData,
  selectedAcquirerData,
  invoiceDraftData,
  dateRange,
  hasPatientInvoiceData,
  setInvoiceNumber,
  handleDateChange,
  toggleChangeInvoiceDataModal,
  toggleChooseAcquirerDataModal,
  handlePaymentDateChange,
  handleSuggestInvoiceNumber,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const hasInvoiceDraftData = useMemo(() => {
    return invoiceDraftData && Object.keys(invoiceDraftData).length > 0;
  }, [invoiceDraftData]);

  const name = useMemo(() => {
    return hasPatientInvoiceData
      ? `${selectedAcquirerData.patientInvoiceData.firstname} ${selectedAcquirerData.patientInvoiceData.lastname}`
      : t("invoice.settings.no_data");
  }, [hasPatientInvoiceData, selectedAcquirerData]);

  const email = useMemo(() => {
    return hasPatientInvoiceData ? selectedAcquirerData.patientInvoiceData.email : "";
  }, [hasPatientInvoiceData, selectedAcquirerData]);

  if (!selectedInvoiceData || !selectedAcquirerData || !invoiceDraftData || !currentClinic) return null;

  return (
    <Card>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <SellerInfo
            title={t("invoice.settings.seller")}
            name={currentClinic.name}
            bankName={selectedInvoiceData.bankName}
            address={selectedInvoiceData.address}
            city={selectedInvoiceData.city}
            postalCode={selectedInvoiceData.postalCode}
            NIP={selectedInvoiceData.NIP}
            REGON={selectedInvoiceData.REGON}
            bankAccountNumber={selectedInvoiceData.bankAccountNumber}
            onClick={toggleChangeInvoiceDataModal}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SellerInfo title={t("invoice.settings.buyer")} name={name} email={email} onClick={toggleChooseAcquirerDataModal} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: palette.grey[200], p: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="invoice-number"
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  label={t("invoice.settings.invoice_number")}
                  value={invoiceNumber}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconWithTooltip
                          label="Zasugeruj numer faktury bazując na numeracji Twoich poprzednich faktur"
                          onClick={handleSuggestInvoiceNumber}
                          icon={<AutoAwesomeRoundedIcon sx={{ color: theme.palette.primary.main }} />}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  value={paymentDate}
                  label={t("Termin płatności")}
                  onChange={handlePaymentDateChange}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  label={t("invoice.settings.date_from")}
                  defaultValue={dateRange.start}
                  onChange={(date) => handleDateChange("start", date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  label={t("invoice.settings.date_to")}
                  defaultValue={dateRange.end}
                  onChange={(date) => handleDateChange("end", date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {hasInvoiceDraftData && (
            <Box sx={{ p: 4 }}>
              <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
                {t("invoice.settings.details")}
              </Typography>
              {Object.entries(invoiceDraftData).map(([status, services], index) => (
                <Box key={index}>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
                    {t(getAppointmentStatusText(status as any))}
                  </Typography>
                  <Grid container spacing={2}>
                    {services.map((service: IInvoiceItem, serviceIndex: number) => (
                      <React.Fragment key={serviceIndex}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField label={t("invoice.settings.service_name")} value={service.name} fullWidth InputProps={{ readOnly: true }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                          <TextField label="Ilość" value={service.count} fullWidth InputProps={{ readOnly: true }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            label={t("invoice.settings.net_price")}
                            value={`${service.costPerCount.toFixed(2)}`}
                            fullWidth
                            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">zł</InputAdornment> }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                          <TextField
                            label={t("invoice.settings.vat")}
                            value={`0`}
                            fullWidth
                            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                          <TextField
                            label={t("invoice.settings.vat_amount")}
                            value={`0`}
                            fullWidth
                            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">zł</InputAdornment> }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            label={t("invoice.settings.net_worth")}
                            value={`${service.totalAmount.toFixed(2)}`}
                            fullWidth
                            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">zł</InputAdornment> }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            label={t("invoice.settings.gross_value")}
                            value={`${service.totalAmount.toFixed(2)}`}
                            fullWidth
                            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">zł</InputAdornment> }}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                  {index < Object.entries(invoiceDraftData).length - 1 && <Divider sx={{ my: 2, borderStyle: "dashed" }} />}
                </Box>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default InvoiceSettings;
