import { rbacCheck } from "@auth/RBAC/RBAC";
import { RoleContext } from "@auth/RBAC/RBACProvider";
import { RBACActions, RBACRoles } from "@auth/rbac-rules";
import ActionMenu from "@components/ActionMenu/ActionMenu";
import PageTitle from "@components/PageTitle/PageTitle";
import ToggleAppointmentStatusModal from "@components/modals/Appointments/ToggleAppointmentStatusModal";
import { SnackbarProps, withSnackbar } from "@hoc/AlertPopover";
import { useModal } from "@hooks/useModal";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Container, Divider, ListItemIcon, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useGetAppointmentTypesQuery } from "@store/services/appointmentTypesService";
import {
  useAddAppointmentMutation,
  useDeleteAppointmentMutation,
  useDeleteManyAppointmentsMutation,
  useEditAppointmentMutation,
  useGetAllAppointmentsQuery,
  useToggleAppointmentStatusMutation,
} from "@store/services/appointmentsService";
import { useGetAllEmployeesQuery } from "@store/services/employeesService";
import { useGetAllPatientsQuery } from "@store/services/patientsService";
import { palette } from "@theme/palette";
import { AppRoutes, AppointmentStatus, NavigateAction, PatientStatus } from "@utils/enums";
import { handleCloseModal } from "@utils/modalUtils";
import { IAppointment, IEvent } from "appointment.types";
import { addWeeks, endOfWeek, format, getDay, isSameDay, parse, parseISO, startOfWeek } from "date-fns";
import { enGB } from "date-fns/locale";
import { useCallback, useContext, useMemo, useState } from "react";
import { Calendar, SlotInfo, dateFnsLocalizer } from "react-big-calendar";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddAppointmentModal from "../../components/modals/Appointments/AddAppointmentModal";
import DeleteAppointmentModal from "../../components/modals/Appointments/DeleteAppointmentModal";
import EditAppointmentModal from "../../components/modals/Appointments/EditAppointmentModal";
import CalendarFilter from "./fragments/CalendarFilter";
import CalendarToolbar from "./fragments/CalendarToolbar";
import CustomHeader from "./fragments/CustomHeader";
import EventComponent from "./fragments/EventComponent";
import EventPopover from "./fragments/EventPopover";
import DeleteManyAppointmentsModal from "./modals/DeleteManyAppointmentsModal";
import "./styles/AppointmentsCalendar.scss";

function getDayStyles(date: Date) {
  const day = getDay(date);
  if (day === 0 || day === 6) {
    return {
      style: {
        backgroundColor: palette.grey[200],
      },
    };
  }
  return {};
}

const localizerConfig = dateFnsLocalizer({
  format: (date: Date, formatString: string) => {
    return format(date, formatString, { locale: enGB });
  },
  parse,
  startOfWeek: () => {
    return startOfWeek(new Date(), { weekStartsOn: 1 });
  },
  getDay,
  locales: {
    "en-GB": enGB,
  },
});

function Appointments({ snackbarShowMessage }: SnackbarProps) {
  const { t } = useTranslation();
  const userRole = useContext(RoleContext);
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(false);
  const [showExcused, setShowExcused] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState<SlotInfo>();
  const [selectedPatients, setSelectedPatients] = useState<string[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<IEvent>();
  const [anchorEventPopover, setAnchorEventPopover] = useState<HTMLButtonElement | null>(null);

  const [isAddAppointmentModalOpen, toggleAddAppointmentModal] = useModal();
  const [isEditAppointmentModalOpen, toggleEditAppointmentModal] = useModal();
  const [isDeleteAppointmentModalOpen, toggleDeleteAppointmentModal] = useModal();
  const [isAppointmentStatusModalOpen, toggleAppointmentStatusModal] = useModal();
  const [isDeleteManyAppointmentsModalOpen, toggleDeleteManyAppointmentsModal] = useModal();

  const [addAppointment, { isLoading: isAddAppointmentLoading }] = useAddAppointmentMutation();
  const [editAppointment, { isLoading: isEditAppointmentLoading }] = useEditAppointmentMutation();
  const [deleteAppointment, { isLoading: isDeleteAppointmentLoading }] = useDeleteAppointmentMutation();
  const [toggleAppointmentStatus, { isLoading: isToggleAppointmentStatusLoading }] = useToggleAppointmentStatusMutation();
  const [deleteManyAppointments, { isLoading: isDeleteManyAppointmentsLoading }] = useDeleteManyAppointmentsMutation();

  const handleCloseAddApointmentModal = () => handleCloseModal([setSelectedEvent], toggleAddAppointmentModal);
  const handleCloseEditAppointmentModal = () => handleCloseModal([setSelectedAppointment], toggleEditAppointmentModal);
  const handleCloseDeleteAppointmentModal = () => handleCloseModal([setSelectedAppointment], toggleDeleteAppointmentModal);
  const handleCloseToggleAppointmentStatusModal = () => handleCloseModal([setSelectedAppointment], toggleAppointmentStatusModal);

  const startWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

  const statusFilter = showExcused
    ? [AppointmentStatus.PRESENT, AppointmentStatus.NOT_EXCUSED, AppointmentStatus.EXCUSED, AppointmentStatus.PENDING]
    : [AppointmentStatus.PRESENT, AppointmentStatus.NOT_EXCUSED, AppointmentStatus.PENDING];

  const { appointmentTypes } = useGetAppointmentTypesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        appointmentTypes: data || [],
      }),
    }
  );

  const { appointments } = useGetAllAppointmentsQuery(
    {
      start: format(startWeek, "yyyy-MM-dd"),
      from: format(endWeek, "yyyy-MM-dd"),
      appointmentStatus: statusFilter,
      assignedEmployees: selectedEmployees,
      patientIds: selectedPatients,
    },
    {
      selectFromResult: ({ data }) => ({
        appointments: data || [],
      }),
    }
  );

  const { patients } = useGetAllPatientsQuery(
    {
      patientStatus: PatientStatus.ACTIVE,
    },
    {
      selectFromResult: ({ data }) => ({
        patients: data || [],
      }),
    }
  );

  const { employees } = useGetAllEmployeesQuery(
    {},
    {
      selectFromResult: ({ data }) => ({
        employees: data || [],
      }),
      skip: userRole === RBACRoles.EMPLOYEE,
    }
  );

  const minTime = useMemo(() => {
    const time = new Date();
    time.setHours(6, 0, 0);
    return time;
  }, []);

  const handleNavigate = useCallback(
    (type?: NavigateAction) => {
      switch (type) {
        case NavigateAction.PREV:
          setCurrentDate(addWeeks(currentDate, -1));
          break;
        case NavigateAction.NEXT:
          setCurrentDate(addWeeks(currentDate, 1));
          break;
        default:
          setCurrentDate(new Date());
          break;
      }
    },
    [currentDate]
  );

  const handlePatientChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setSelectedPatients(event.target.value as string[]);
    },
    [setSelectedEmployees]
  );

  const handleEmployeeChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setSelectedEmployees(event.target.value as string[]);
    },
    [setSelectedEmployees]
  );

  const handleSwitchChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: boolean) => {
      setShowExcused(value);
    },
    [setShowExcused]
  );

  const handleSelectSlot = useCallback((event: SlotInfo) => {
    setSelectedEvent(event);
    toggleAddAppointmentModal();
  }, []);

  const handleCloseEventPopover = useCallback(() => {
    setSelectedAppointment(null);
    setAnchorEventPopover(null);
  }, []);

  const handleAddAppointment = useCallback(
    (appointmentData: Partial<IAppointment>) => {
      // @ts-expect-error
      if (!isSameDay(appointmentData.startDate, appointmentData.endDate)) {
        snackbarShowMessage(t("appointments.snackbar_message.date_error"), "error");
        return;
      }

      addAppointment(appointmentData)
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.add.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.add.error"), "error");
        })
        .finally(() => {
          toggleAddAppointmentModal();
        });
    },
    [addAppointment, snackbarShowMessage, toggleAddAppointmentModal]
  );

  const handleEditAppointment = useCallback(
    (appointmentData: Partial<IAppointment>) => {
      if (!selectedAppointment) return;
      if (!isSameDay(new Date(appointmentData.startDate), new Date(appointmentData.endDate))) {
        snackbarShowMessage(t("appointments.snackbar_message.date_error"), "error");
        return;
      }

      editAppointment({ appointmentId: selectedAppointment._id, data: appointmentData })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.edit.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.edit.error"), "error");
        })
        .finally(() => {
          handleCloseEditAppointmentModal();
        });
    },
    [editAppointment, snackbarShowMessage, selectedAppointment]
  );

  const handleDeleteAppointment = useCallback(
    (deleteSeries: boolean) => {
      if (!selectedAppointment) return;

      deleteAppointment({ appointmentId: selectedAppointment._id, deleteSeries })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.delete.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.delete.error"), "error");
        })
        .finally(() => {
          handleCloseDeleteAppointmentModal();
        });
    },
    [deleteAppointment, snackbarShowMessage, selectedAppointment]
  );

  const handleToggleAppointmentStatus = useCallback(
    (status: AppointmentStatus) => {
      toggleAppointmentStatus({ appointmentId: selectedAppointment?._id, data: { appointmentStatus: status } })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.toggle_status.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.toggle_status.error"), "error");
        })
        .finally(() => {
          toggleAppointmentStatusModal();
          handleCloseEventPopover();
        });
    },
    [toggleAppointmentStatus, snackbarShowMessage, selectedAppointment]
  );

  const handleDeleteManyAppointments = useCallback(
    (values: { patientId: string; startDate: string; endDate: string }) => {
      deleteManyAppointments({
        patientId: values.patientId,
        startDate: values.startDate,
        endDate: values.endDate,
      })
        .unwrap()
        .then(() => {
          snackbarShowMessage(t("appointments.snackbar_message.delete_many.success"), "success");
        })
        .catch(() => {
          snackbarShowMessage(t("appointments.snackbar_message.delete_many.error"), "error");
        })
        .finally(() => {
          toggleDeleteManyAppointmentsModal();
          setOpenMenu(false);
        });
    },
    [toggleAppointmentStatus, snackbarShowMessage, selectedAppointment]
  );

  if (!rbacCheck(userRole, RBACActions.MAIN_MENU_APPOINTMENTS)) navigate(AppRoutes.Dashboard);

  return (
    <>
      <Helmet>
        <title>{t("appointments.helmet")}</title>
      </Helmet>
      <Container>
        <PageTitle
          title={t("appointments.title")}
          rightContent={
            <Stack direction="row" spacing={2}>
              <ActionMenu
                openMenu={openMenu}
                menuItems={
                  <>
                    <MenuItem onClick={toggleAddAppointmentModal}>
                      <ListItemIcon>
                        <AddRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t("appointments.button.add")}
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={toggleDeleteManyAppointmentsModal}>
                      <ListItemIcon>
                        <DeleteRoundedIcon fontSize="small" color="error" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap color="error">
                        {t("appointments.delete_period")}
                      </Typography>
                    </MenuItem>
                  </>
                }
                buttonLabel={t("appointments.menu")}
                setOpenMenu={setOpenMenu}
              />
            </Stack>
          }
        />
        <CalendarFilter
          showExcused={showExcused}
          employees={employees}
          patients={patients}
          selectedPatients={selectedPatients}
          selectedEmployees={selectedEmployees}
          handlePatientChange={handlePatientChange}
          handleEmployeeChange={handleEmployeeChange}
          handleSwitchChange={handleSwitchChange}
        />
        <Calendar
          min={minTime}
          dayPropGetter={getDayStyles}
          selectable
          step={15}
          onSelectEvent={(event, e: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEventPopover(e.currentTarget);
            setSelectedAppointment(event);
          }}
          onSelectSlot={(event) => handleSelectSlot(event)}
          date={currentDate}
          localizer={localizerConfig}
          events={appointments}
          defaultView="week"
          views={["week"]}
          onNavigate={() => null}
          components={{
            event: ({ event }) => EventComponent({ event }),
            toolbar: () => (
              <CalendarToolbar
                {...{ showExcused, handleSwitchChange, selectedEmployees, employees, startWeek, endWeek, handleNavigate, handleEmployeeChange }}
              />
            ),
            header: ({ date }) => <CustomHeader {...{ date }} />,
          }}
          startAccessor={(event) => parseISO(event.startDate)}
          endAccessor={(event) => parseISO(event.endDate)}
          style={{ height: 700 }}
        />
      </Container>
      <EventPopover
        {...{ anchorEventPopover }}
        {...{ selectedAppointment }}
        {...{ handleCloseEventPopover }}
        {...{ toggleEditAppointmentModal, toggleDeleteAppointmentModal }}
        {...{
          toggleEditAppointmentModal,
          toggleDeleteAppointmentModal,
          toggleAppointmentStatusModal,
        }}
      />
      <AddAppointmentModal
        appointmentTypes={appointmentTypes}
        patients={patients}
        event={selectedEvent}
        open={isAddAppointmentModalOpen}
        onClose={handleCloseAddApointmentModal}
        onSubmit={handleAddAppointment}
        loading={isAddAppointmentLoading}
      />
      <EditAppointmentModal
        patients={patients}
        appointmentTypes={appointmentTypes}
        open={isEditAppointmentModalOpen}
        initialValues={selectedAppointment}
        onClose={handleCloseEditAppointmentModal}
        onSubmit={handleEditAppointment}
        loading={isEditAppointmentLoading}
      />
      <DeleteAppointmentModal
        selectedAppointment={selectedAppointment}
        open={isDeleteAppointmentModalOpen}
        onClose={handleCloseDeleteAppointmentModal}
        onSubmit={handleDeleteAppointment}
        loading={isDeleteAppointmentLoading}
      />
      <ToggleAppointmentStatusModal
        selectedAppointment={selectedAppointment}
        open={isAppointmentStatusModalOpen}
        onClose={handleCloseToggleAppointmentStatusModal}
        onSubmit={handleToggleAppointmentStatus}
        loading={isToggleAppointmentStatusLoading}
      />
      <DeleteManyAppointmentsModal
        open={isDeleteManyAppointmentsModalOpen}
        onClose={toggleDeleteManyAppointmentsModal}
        patients={patients}
        onSubmit={handleDeleteManyAppointments}
        loading={isDeleteManyAppointmentsLoading}
      />
    </>
  );
}
export default withSnackbar(Appointments);
